import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css'; 
import { 
  Button, 
  MenuItem, 
  Select, 
  AppBar, 
  Toolbar, 
  Box, 
  Grid, 
  Typography, 
  TextField,
  Dialog, 
  DialogContent, 
  DialogTitle, 
  DialogActions,
  Divider // Add this import
} from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { getFleets, getFleet, getPrice, getOperatorData, requestCode, signUp, createCheckoutSession, checkVehicleAvailable, getCoupon } from '../api';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { loadStripe } from '@stripe/stripe-js';
import DOMPurify from 'dompurify';
import CryptoJS from 'crypto-js';

function createHash(reservation_id) {
  const combinedInput = `${reservation_id}${Date.now()}`;
  const hash = CryptoJS.SHA256(combinedInput).toString(CryptoJS.enc.Hex);
  return hash.slice(0, 8).toUpperCase(); 
}

const ReservationPage = () => {
  const navigate = useNavigate(); 
  const loc = useLocation();
  const [fleets, setFleets] = useState([]); 
  const [vehicleTypes, setVehicleTypes] = useState([]); 
  const [vehicleImage, setVehicleImage] = useState([]);
  const [selectedVehicleType, setSelectedVehicleType] = useState(''); 
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
  const [selectedVehicle, setSelectedVehicle] = useState(null); 
  const [dateRangeSelected, setDateRangeSelected] = useState(false);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  const [selectedVehicleName, setSelectedVehicleName] = useState([]);
  const [priceDetails, setPriceDetails] = useState(null);
  const [initialVehicleTypeSet, setInitialVehicleTypeSet] = useState(false); 
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [couponCode, setCouponCode] = useState(''); 
  const [couponId, setCouponId] = useState(null); 
  const [couponDescription, setCouponDescription] = useState(''); 
  const [hasCoupon, setHasCoupon] = useState(false); 
  const [couponPercent, setCouponPercent] = useState(null); 
  const [submitDisabled, setSubmitDisabled] = useState(false); 

  const [isLoginDialogOpen, setIsLoginDialogOpen] = useState(false);
  const [isSignInDialogOpen, setIsSignInDialogOpen] = useState(false);
  const [fleetParam, setFleetParam] = useState('');
  const shownDate = new Date();

  const handleViewPrices = useCallback(async () => {
    try {
      if (!selectedVehicleType) {
        alert("Please select a vehicle type");
        return;
      }
      
      if (!dateRangeSelected) {
        // Don't fetch price if no date range has been selected
        return;
      }
      
      const fleet = JSON.parse(localStorage.getItem('fleet'));
      const start_date = selectionRange.startDate.toISOString();
      setStartDate(start_date);
      const end_date = selectionRange.endDate.toISOString();
      setEndDate(end_date);

      // Fetch price details
      const response = await getPrice(fleet.id, start_date, end_date, selectedVehicleType);

      // Convert base_charge from cents to dollars
      const originalBaseCharge = parseFloat(response.base_charge) / 100;
      const originalAmount = parseFloat(response.amount);
      const originalTaxes = parseFloat(response.taxes) / 100; // Convert taxes from cents to dollars

      if (isNaN(originalBaseCharge) || isNaN(originalAmount) || isNaN(originalTaxes)) {
        console.error("Invalid price data received");
        return;
      }

      let discountedBaseCharge = originalBaseCharge;
      let newAmount = originalAmount;
      let newTaxes = originalTaxes;

      if (couponPercent) {
        // Apply the discount to the base charge
        discountedBaseCharge = +(originalBaseCharge * (1 - couponPercent / 100)).toFixed(2);
        console.log("Discounted base charge:", discountedBaseCharge);

        // Adjust the taxes proportionally to the discount
        const discountFactor = discountedBaseCharge / originalBaseCharge;
        newTaxes = +(originalTaxes * discountFactor).toFixed(2);
        console.log("Adjusted taxes:", newTaxes);

        // Calculate the new total amount (base charge + adjusted taxes)
        newAmount = +(discountedBaseCharge + newTaxes).toFixed(2);
        console.log("New total amount:", newAmount);
      }

      // Update priceDetails with new values
      const newPriceDetails = {
        ...response,
        base_charge: +(discountedBaseCharge * 100).toFixed(0), // Convert back to cents
        taxes: +(newTaxes * 100).toFixed(0), // Convert back to cents
        amount: newAmount,
      };

      // Set the updated price details in state
      setPriceDetails(newPriceDetails);
    } catch (error) {
      console.error("Error fetching price details:", error);
    }
  }, [selectedVehicleType, selectionRange, couponPercent, dateRangeSelected]);

  useEffect(() => {
    const handleResize = () => {
      const currentIsMobile = window.innerWidth <= 800;
      setIsMobile(currentIsMobile); 
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);  

  useEffect(() => {
    const queryParams = new URLSearchParams(loc.search);
    const fleetParam = queryParams.get('fleet');
    const operatorParam = queryParams.get('operator');
    const vehicleParam = queryParams.get('vehicle');

    if (operatorParam) {
      getOperatorData(operatorParam).then((operatorResponse) => {
        localStorage.setItem('operator', JSON.stringify(operatorResponse))
      });
      getFleets(operatorParam)
        .then((data) => setFleets(data.fleets))
        .catch((error) => console.error('Failed to load fleets:', error));
    }

    if (fleetParam) {
      getFleet(fleetParam)
        .then((fleetData) => {
          const fleet = fleetData?.fleet;
          if (fleet) {
            localStorage.setItem('fleet', JSON.stringify(fleet));
            setVehicleTypes(fleet.vehicle_types || []);
            setFleetParam(fleetParam)

            if (!initialVehicleTypeSet && vehicleParam && fleet.vehicle_types) {
              const defaultVehicle = fleet.vehicle_types.find(vehicle => vehicle.vehicle_type_id === parseInt(vehicleParam, 10));
              if (defaultVehicle) {
                setSelectedVehicleType(defaultVehicle.vehicle_type_id);
                setSelectedVehicle(defaultVehicle);
                const vehicle_image = defaultVehicle.features.vehicle_image;
                setVehicleImage(vehicle_image);
                setSelectedVehicleName(defaultVehicle.vehicle_type_name);
                setInitialVehicleTypeSet(true);
              }
            } 
          }
        })
        .catch((error) => console.error('Failed to fetch fleet data:', error));
    }

    if (selectionRange.startDate && selectionRange.endDate && selectedVehicleType) {
      handleViewPrices();
    }
  }, [navigate, loc.search, selectionRange, selectedVehicleType, initialVehicleTypeSet, handleViewPrices]);



  const handleFleetChange = async (event) => {
    const selectedFleetId = event.target.value;
    try {
      const fleetData = await getFleet(selectedFleetId);
      const fleet = fleetData?.fleet;
      if (fleet) {
        setFleetParam(selectedFleetId)
        localStorage.setItem('fleet', JSON.stringify(fleet));
        setVehicleTypes(fleet.vehicle_types || []);
        setInitialVehicleTypeSet(false);
      }
    } catch (error) {
      console.error('Failed to fetch and set fleet data:', error);
    }
  };

  const handleVehicleTypeChange = (event) => {
    const vehicle_type = event.target.value;
    setSelectedVehicleType(vehicle_type);
    const selectedVehicle = vehicleTypes.find(vehicle => vehicle.vehicle_type_id === vehicle_type);
    const vehicle_image = selectedVehicle.features.vehicle_image;
    setVehicleImage(vehicle_image);
    setSelectedVehicle(selectedVehicle); 
    setSelectedVehicleName(selectedVehicle.vehicle_type_name);
  };

  const handleReserveClick = async () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); 

    if (selectionRange.startDate < today) {
      alert("The start date cannot be in the past. Please select a valid date range.");
      return;
    }

    try {
      await checkVehicleAvailable(selectedVehicleType, fleet.id, startDate, endDate);
      setIsLoginDialogOpen(true); 
    } catch (error) {
      alert(error.message);
    }
  };

  const handleDateChange = (ranges) => {
    setSelectionRange(ranges.selection);
    setDateRangeSelected(true); // Mark that a date range has been explicitly selected
  };

  const handleSubmitCoupon = async () => {
    try {
      const response = await getCoupon(fleet.id, couponCode);

      if (response && response.ok) {
        const data = await response.json(); 
        if (Array.isArray(data) && data.length === 0) {
          alert('No coupon found for the provided code.');
        } else if (data && data[0].description && data[0].discount_pct) {
          setCouponDescription(data[0].description);
          setCouponPercent(data[0].discount_pct);
          setCouponId(data[0].id)
          setHasCoupon(true);
          setSubmitDisabled(true); 
        } else {
          alert('Invalid coupon data received');
        }
      } else {
        console.log('Response not OK');
      }
    } catch (error) {
      console.error('Coupon submission error:', error);
      alert('Coupon submission error');
    }
  };
  
  const fleet = JSON.parse(localStorage.getItem('fleet')) || {};

  const LoginDialogContent = ({ onNext }) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [error, setError] = useState('');

    const handlePhoneSubmit = async () => {
      try {
        const fullPhoneNumber = `+${phoneNumber}`;
        const phoneNumberObject = parsePhoneNumberFromString(fullPhoneNumber);
        if (!phoneNumberObject) throw new Error('Invalid phone number');

        const localPhoneNumber = phoneNumberObject.nationalNumber;
        const countryCode = phoneNumberObject.countryCallingCode;

        localStorage.setItem('phone', localPhoneNumber);
        localStorage.setItem('country_code', countryCode);

        await requestCode(localPhoneNumber, countryCode);
        onNext(); 
      } catch (error) {
        setError('Failed to send SMS code. Please try again.');
      }
    };

    return (
      <>
        <DialogTitle>To finalize this booking, please verify your mobile number</DialogTitle>
        <DialogContent>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: '16px', marginLeft: '15%' }}>
            <PhoneInput
              country={'us'}
              value={phoneNumber}
              onChange={setPhoneNumber}
              inputStyle={{ width: '50%' }} 
            />
          </div>
          {error && <Typography color="error" style={{ marginLeft: '33%' }}>{error}</Typography>}
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handlePhoneSubmit} 
            variant="contained" 
            color="primary" 
            sx={{
              backgroundColor: '#00B894',
              color: 'white',
              borderRadius: '24px',
              height: '40px',
              marginLeft: '10%', 
              padding: '0 16px',
              '&:hover': {
                backgroundColor: '#00A983',
              },
            }}  
          >
            NEXT
          </Button>
        </DialogActions>
      </>
    );
  };

  const SignInDialogContent = ({ onSuccess }) => {
    const [code, setCode] = useState('');
    const [error, setError] = useState('');

    const handleCodeSubmit = async () => {
      if (!code.match(/^\d{4}$/)) {
        setError('Please enter a valid 4-digit code.');
        return;
      }
      try {
        const phone = localStorage.getItem('phone');
        const operatorString = localStorage.getItem('operator'); // Retrieve as string
        const operator = JSON.parse(operatorString); // Parse the JSON string into an object
        if (!operator || !operator.stripe_pk) {
          console.error('Operator or stripe_pk is not defined.');
          setError('Operator information is missing. Please refresh the page.');
          return;
        }
        const stripePromise = loadStripe(operator.stripe_pk);
        const user = await signUp(phone, code, operator.id);
        const waiver_status = user.waiver_status;
        const user_id = user.id;
        const stripe_customer_id = user.stripe_customer_id;

        const fleet = JSON.parse(localStorage.getItem('fleet'));
        const matchingVehicleType = fleet.vehicle_types.find(vehicle => vehicle.vehicle_type_id === selectedVehicleType);
        const features = Array.isArray(matchingVehicleType.features)
          ? matchingVehicleType.features[0]
          : matchingVehicleType.features;

        const baseUrl = window.location.origin;
        let smartwaiver_url = null;
        if (waiver_status === null) {
          smartwaiver_url = features.smartwaiver_url + '?auto_tag=' + user_id;
        }
     
        const has_security_preauth = features.has_security_preauth;
        const security_preauth_amount = features.security_preauth_amount;
        const reservation_amount = priceDetails.amount;
        const base_charge = priceDetails.base_charge;
        const taxes = priceDetails.taxes;
        const start_date = startDate;
        const end_date = endDate;
        const reservation_id = priceDetails.reservation_id;
        const fleet_timezone = fleet.iso_timezone;
        const fleet_name = fleet.account_name;
        const dayCnt = priceDetails.days; 
        const vehicle_name = selectedVehicle.vehicle_type_name;
        const vehicle_image_url = selectedVehicle.features.vehicle_image;
        const stripe = await stripePromise;
        const coupon_id = couponId;
        const reservation_hash = createHash(reservation_id);

        const success_url = baseUrl + '/checkout?operator=' + operator.id 
        + '&fleet=' + fleetParam
        + '&reservation_amount=' + reservation_amount
        + '&vehicle_image_url=' + vehicle_image_url
        + '&vehicle_name=' + vehicle_name
        + '&start_date=' + start_date
        + '&end_date=' + end_date
        + '&dayCnt=' + dayCnt
        + '&smartwaiver_url=' + smartwaiver_url
        + '&reservation_hash=' + reservation_hash
        
        const params = {
          user_id,
          stripe_customer_id,
          reservation_amount,
          success_url,
          has_security_preauth,
          security_preauth_amount,
          base_charge,
          taxes,
          start_date,
          end_date,
          reservation_id,
          fleet_timezone,
          fleet_name,
          dayCnt, 
          vehicle_name,
          vehicle_image_url,
          coupon_id,
          reservation_hash
        };
        const session = await createCheckoutSession(params);
        const { error } = await stripe.redirectToCheckout({ sessionId: session.id });
        if (error) {
          setError('Failed to redirect to payment. Please try again.');
        }
      } catch (error) {
        setError(`Failed to sign in. ${error.message}`);
      }
    };

    return (
      <>
        <DialogTitle>Enter the SMS Code sent to your phone</DialogTitle>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: '16px', marginLeft: '15%' }}>
          <DialogContent>
            <TextField
              label="SMS Code"
              value={code}
              onChange={(e) => setCode(e.target.value.replace(/[^0-9]/g, ''))}
              error={!!error}
              helperText={error}
              sx={{ width: '30%' }}
            />
          </DialogContent>
        </div>
        <DialogActions>
          <Button 
            onClick={handleCodeSubmit} 
            variant="contained" 
            color="primary" 
            sx={{
              backgroundColor: '#00B894',
              color: 'white',
              borderRadius: '24px',
              height: '40px',
              marginLeft: '10%', 
              padding: '0 16px',
              '&:hover': {
                backgroundColor: '#00A983',
              },
            }}
          >
            NEXT
          </Button>
        </DialogActions>
      </>
    );
  };

  return (
    <div style={{ backgroundColor: '#f7f9fc', minHeight: '100vh' }}>
      <AppBar position="static" style={{ backgroundColor: fleet.menu_bkg_color || '#1a73e8' }}>
        <Toolbar>
          <Box
            component="img"
            src={fleet.web_logo || 'default_logo.png'}
            alt="Menu Logo"
            sx={{
              backgroundColor: fleet.web_bkg_color || '#fff',
              padding: '8px',
              borderRadius: '8px',
              height: '125px',
              objectFit: 'contain',
              boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
            }}
          />
        </Toolbar>
      </AppBar>
      <Box mt={4} mx="auto" width="98%" maxWidth="1400px" pt={4}>
        {/* Redesigned dropdown container with better symmetry */}
        <Grid container spacing={4} mb={4} justifyContent="center">
          <Grid item xs={12} md={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <Typography variant="h6" sx={{ fontWeight: 600, color: '#333', mb: 1, pl: 1 }}>Fleet</Typography>
              <Select
              value={fleets.some(f => f.id === fleet.id) ? fleet.id : ''}
              onChange={handleFleetChange}
              disabled={!fleet.id}
              sx={{ 
                width: '100%',
                maxWidth: '320px',
                bgcolor: 'white', 
                borderRadius: '8px',
                boxShadow: '0 2px 5px rgba(0,0,0,0.08)',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(0,0,0,0.1)'
                }
              }}
            >
              {fleets.map(fleet => (
                <MenuItem key={fleet.id} value={fleet.id}>
                  {fleet.account_name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} md={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <Typography variant="h6" sx={{ fontWeight: 600, color: '#333', mb: 1, pl: 1 }}>Vehicle Type</Typography>
            <Select
              value={selectedVehicleType}
              onChange={handleVehicleTypeChange}
              disabled={!fleet.id}
              sx={{ 
                width: '100%',
                maxWidth: '320px',
                bgcolor: 'white', 
                borderRadius: '8px',
                boxShadow: '0 2px 5px rgba(0,0,0,0.08)',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(0,0,0,0.1)'
                }
              }}
            >
              {vehicleTypes.map(vehicleType => (
                <MenuItem key={vehicleType.vehicle_type_id} value={vehicleType.vehicle_type_id}>
                  {vehicleType.vehicle_type_name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid 
            item
            style={{ 
              width: isMobile ? '100%' : '30%', // Explicit width
              padding: 10, 
              position: 'relative',
              zIndex: 1000,
              display: 'flex',
              flexDirection: 'column'
            }}
          > 
            <Box
              style={{
                width: isMobile ? '377px' : '397px', 
                minHeight: isMobile ? '338px' : '375px',
                margin: 0,
                marginTop: '-9px',
                padding: '15px',
                position: 'relative',
                backgroundColor: '#fff',
                boxShadow: '0 4px 20px rgba(0,0,0,0.12)',
                borderRadius: '10px',
                overflow: 'visible',
                marginBottom: '1.5rem',
                left: isMobile ? '-15px' : '0',
                transform: isMobile ? 'scale(.9)' : 'none',
                transformOrigin: 'top left',
                border: '1px solid rgba(0,0,0,0.05)'
              }}
            >
              <DateRange
                ranges={[selectionRange]}
                onChange={handleDateChange}
                shownDate={shownDate}
                months={1}
                direction="horizontal"
                showDateDisplay={false}
                style={{ 
                  margin: 0,
                  padding: 0,
                  position: 'relative',
                  zIndex: 1001,
                  transform: 'scale(1.25)', // Scale the calendar component itself
                  transformOrigin: 'top left' // Ensure it scales from the top left
                }}
                color="#00B894"
                rangeColors={['#00B894', '#CCEEEA', '#EAFCFA']}
              />
            </Box>
            
            <div style={{ height: '120px' }} />
            
            <Box 
              sx={{ 
                width: '90%', 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'flex-start',
                position: 'relative',
                top: 0,
                zIndex: 999,
                backgroundColor: '#ffffff',
                padding: '1.2rem',
                borderRadius: '10px',
                boxShadow: '0 4px 15px rgba(0,0,0,0.08)',
                border: '1px solid rgba(0,0,0,0.05)'
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: 600, color: '#333', mb: 1 }}>Enter Coupon</Typography>
              <TextField
                value={couponCode}
                onChange={(e) => setCouponCode(e.target.value)}
                placeholder="Enter your coupon code"
                fullWidth
                sx={{ 
                  marginTop: 1, 
                  marginBottom: 1,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '8px',
                    '&.Mui-focused fieldset': {
                      borderColor: '#00B894'
                    }
                  }
                }}
              />
              <Button
                onClick={handleSubmitCoupon}
                variant="contained"
                color="primary"
                disabled={submitDisabled}
                sx={{ 
                  mt: 2,
                  bgcolor: '#00B894',
                  borderRadius: '8px',
                  boxShadow: '0 2px 8px rgba(0,184,148,0.3)',
                  '&:hover': {
                    backgroundColor: '#00A983',
                  },
                  '&:disabled': {
                    backgroundColor: '#cccccc',
                  }
                }}
              >
                Apply Coupon
              </Button>

              {hasCoupon && (
                <Typography 
                  variant="body1" 
                  sx={{ 
                    mt: 2, 
                    color: "#00B894", 
                    bgcolor: 'rgba(0,184,148,0.1)', 
                    p: 1, // Reduced padding from 1.5 to 1
                    borderRadius: '6px', 
                    width: 'calc(100% - 8px)', // Adjusted width to account for padding
                    fontWeight: 500,
                    wordBreak: 'break-word', // Allow text to break to prevent overflow
                    overflow: 'hidden', // Hide any overflow
                    boxSizing: 'border-box' // Include padding in the width calculation
                  }}
                >
                  Coupon added: {couponDescription}
                </Typography>
              )}
            </Box>
          </Grid>
          
          <div style={{ width: isMobile ? 0 : '80px' }} /> {/* Explicit horizontal spacer */}
          
          <Grid 
            item
            style={{ 
              width: isMobile ? '100%' : 'calc(70% - 80px)', // Width minus the spacer width
              padding: 0,
              position: 'relative',
              zIndex: 900
            }}
          >
            {selectedVehicleType && (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="flex-start"
                sx={{ 
                  width: '100%', 
                  textAlign: 'center', 
                  height: '100%',
                  position: 'relative',
                  backgroundColor: '#ffffff',
                  borderRadius: '10px',
                  boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
                  p: 3,
                  border: '1px solid rgba(0,0,0,0.05)'
                }}
              >
                {/* Vehicle image is always shown when a vehicle is selected */}
                {isMobile === false && (
                  <div style={{ 
                    width: '100%', 
                    display: 'flex', 
                    justifyContent: 'center', 
                    overflow: 'visible',
                    marginTop: '10px',
                    marginBottom: '30px'
                  }}>
                    <img 
                      src={vehicleImage} 
                      alt="Vehicle" 
                      style={{ 
                        height: 'auto',
                        maxWidth: '90%',
                        objectFit: 'contain',
                        borderRadius: '8px',
                        boxShadow: '0 4px 15px rgba(0,0,0,0.1)',
                        padding: '10px'
                      }} 
                    />
                  </div>
                )}

                <Typography
                  variant="h5" 
                  component="div" 
                  style={{ 
                    color: '#333', 
                    fontSize: isMobile ? 18 : 24,
                    fontWeight: 600,
                    textAlign: 'center',
                    marginBottom: '8px'
                  }}
                >
                  {selectedVehicleName}
                </Typography>

                {/* Rental details are conditionally shown based on date selection */}
                {dateRangeSelected && selectionRange.startDate && selectionRange.endDate && priceDetails ? (
                  <Box
                    sx={{
                      textAlign: 'left',
                      width: '100%',
                      background: 'rgba(0,184,148,0.1)',
                      padding: '16px',
                      borderRadius: '8px',
                      marginBottom: '16px'
                    }}
                  >
                    <Typography
                      component="div"
                      sx={{
                        fontSize: isMobile ? 18 : 22,
                        fontWeight: 600,
                        color: '#00B894',
                        marginBottom: '12px',
                        textAlign: 'center'
                      }}
                    >
                      Rental Details
                    </Typography>
                    
                    <Grid container spacing={1}>
                      <Grid item xs={7}>
                        <Typography component="div" sx={{ fontSize: isMobile ? 14 : 16, fontWeight: 500 }}>
                          Duration:
                        </Typography>
                      </Grid>
                      <Grid item xs={5} sx={{ textAlign: 'right' }}>
                        <Typography component="div" sx={{ fontSize: isMobile ? 14 : 16 }}>
                          {priceDetails.days} {priceDetails.days === 1 ? 'day' : 'days'}
                        </Typography>
                      </Grid>
                      
                      {/* Add start date */}
                      <Grid item xs={7}>
                        <Typography component="div" sx={{ fontSize: isMobile ? 14 : 16, fontWeight: 500 }}>
                          Start Date:
                        </Typography>
                      </Grid>
                      <Grid item xs={5} sx={{ textAlign: 'right' }}>
                        <Typography component="div" sx={{ fontSize: isMobile ? 14 : 16 }}>
                          {selectionRange.startDate.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' })}
                        </Typography>
                      </Grid>
                      
                      {/* Add end date */}
                      <Grid item xs={7}>
                        <Typography component="div" sx={{ fontSize: isMobile ? 14 : 16, fontWeight: 500 }}>
                          End Date:
                        </Typography>
                      </Grid>
                      <Grid item xs={5} sx={{ textAlign: 'right' }}>
                        <Typography component="div" sx={{ fontSize: isMobile ? 14 : 16 }}>
                          {selectionRange.endDate.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' })}
                        </Typography>
                      </Grid>
                      
                      <Grid item xs={7}>
                        <Typography component="div" sx={{ fontSize: isMobile ? 14 : 16, fontWeight: 500 }}>
                          Base Charge:
                        </Typography>
                      </Grid>
                      <Grid item xs={5} sx={{ textAlign: 'right' }}>
                        <Typography component="div" sx={{ fontSize: isMobile ? 14 : 16 }}>
                          ${(priceDetails.base_charge / 100).toFixed(2)}
                        </Typography>
                      </Grid>
                      
                      {/* Display each surcharge with its description */}
                      {priceDetails.surcharges && priceDetails.surcharges.map((surcharge, index) => (
                        surcharge.calculated_amount > 0 && (
                          <React.Fragment key={index}>
                            <Grid item xs={7}>
                              <Typography component="div" sx={{ fontSize: isMobile ? 14 : 16, fontWeight: 500 }}>
                                {surcharge.description}:
                              </Typography>
                            </Grid>
                            <Grid item xs={5} sx={{ textAlign: 'right' }}>
                              <Typography component="div" sx={{ fontSize: isMobile ? 14 : 16 }}>
                                ${(surcharge.calculated_amount / 100).toFixed(2)}
                              </Typography>
                            </Grid>
                          </React.Fragment>
                        )
                      ))}
                      
                      {/* Display discount if applicable */}
                      {couponPercent > 0 && (
                        <>
                          <Grid item xs={7}>
                            <Typography component="div" sx={{ fontSize: isMobile ? 14 : 16, fontWeight: 500, color: '#00B894' }}>
                              Discount:
                            </Typography>
                          </Grid>
                          <Grid item xs={5} sx={{ textAlign: 'right' }}>
                            <Typography component="div" sx={{ fontSize: isMobile ? 14 : 16, color: '#00B894' }}>
                              {couponPercent}%
                            </Typography>
                          </Grid>
                        </>
                      )}

                      {/* Display taxes */}
                      {priceDetails.taxes > 0 && (
                        <>
                          <Grid item xs={7}>
                            <Typography component="div" sx={{ fontSize: isMobile ? 14 : 16, fontWeight: 500 }}>
                              Taxes:
                            </Typography>
                          </Grid>
                          <Grid item xs={5} sx={{ textAlign: 'right' }}>
                            <Typography component="div" sx={{ fontSize: isMobile ? 14 : 16 }}>
                              ${(priceDetails.taxes / 100).toFixed(2)}
                            </Typography>
                          </Grid>
                        </>
                      )
                      }
                                            
                      <Grid item xs={12}><Divider sx={{ my: 1 }} /></Grid>
                      
                      <Grid item xs={7}>
                        <Typography component="div" sx={{ fontSize: isMobile ? 16 : 18, fontWeight: 600 }}>
                          Total:
                        </Typography>
                      </Grid>
                      <Grid item xs={5} sx={{ textAlign: 'right' }}>
                        <Typography component="div" sx={{ fontSize: isMobile ? 16 : 18, fontWeight: 600 }}>
                          ${parseFloat(priceDetails.amount).toFixed(2)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      textAlign: 'center',
                      width: '100%',
                      background: 'rgba(0,184,148,0.1)',
                      padding: '16px',
                      borderRadius: '8px',
                      marginBottom: '16px'
                    }}
                  >
                    <Typography
                      component="div"
                      sx={{
                        fontSize: isMobile ? 16 : 18,
                        fontWeight: 500,
                        color: '#00B894',
                      }}
                    >
                      Please select a date range
                    </Typography>
                  </Box>
                )}
                
                {selectedVehicle && selectedVehicle.features && selectedVehicle.features.reservation_description && (
                  <Typography
                    component="div"
                    style={{
                      color: '#555',
                      fontSize: isMobile ? 14 : 16,
                      textAlign: 'left',
                      width: '100%',
                      padding: '10px',
                      background: '#f9f9f9',
                      borderRadius: '8px',
                      marginBottom: '20px'
                    }}
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(selectedVehicle.features.reservation_description) }}
                  />
                )}
                <Button
                  onClick={handleReserveClick}
                  variant="contained"
                  color="primary"
                  sx={{
                    backgroundColor: '#00B894',
                    color: 'white',
                    borderRadius: '30px',
                    height: '48px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    outline: 'none',
                    border: 'none',
                    alignSelf: 'center',
                    marginTop: '16px',
                    padding: '0 32px',
                    fontSize: '16px',
                    fontWeight: 600,
                    boxShadow: '0 4px 12px rgba(0,184,148,0.3)',
                    '&:hover': {
                      backgroundColor: '#00A983',
                      boxShadow: '0 6px 16px rgba(0,184,148,0.4)',
                    },
                  }}
                >
                  RESERVE NOW
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
  
      <Dialog 
        open={isLoginDialogOpen} 
        onClose={() => setIsLoginDialogOpen(false)} 
        fullWidth 
        maxWidth="sm"
        PaperProps={{
          style: {
            borderRadius: '12px',
            padding: '10px',
            boxShadow: '0 10px 30px rgba(0,0,0,0.15)'
          }
        }}
      >
        <LoginDialogContent onNext={() => { setIsLoginDialogOpen(false); setIsSignInDialogOpen(true); }} />
      </Dialog>
  
      <Dialog 
        open={isSignInDialogOpen} 
        onClose={() => setIsSignInDialogOpen(false)} 
        fullWidth 
        maxWidth="sm"
        PaperProps={{
          style: {
            borderRadius: '12px',
            padding: '10px',
            boxShadow: '0 10px 30px rgba(0,0,0,0.15)'
          }
        }}
      >
        <SignInDialogContent onSuccess={() => setIsSignInDialogOpen(false)} />
      </Dialog>
    </div>
  );
};

export default ReservationPage;
